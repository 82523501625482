<style scoped>
    .main-content {
        font-family: 'Poppins', sans-serif;
    }

    .dark,
    label {
        color: #1d2428;
    }

    @media (max-width: 768px) {
        .select-country {
            margin-top: -45px;
        }
    }

    label {
        font-weight: 600;
    }

    .bg-zebre {
        position: fixed;
        width: 50vh;
        max-width: 700px;
        left: 0;
        bottom: 0;
        z-index: 0;
    }

    .bg-zebre-droite {
        position: fixed;
        width: 50vh;
        max-width: 700px;
        right: 0;
        bottom: 0;
        z-index: 0;
    }

    .section {
        position: absolute;
        width: 100%;
        min-height: 100vh;
        background-color: rgb(29, 36, 40);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-image: url(https://www.nextlevel.link/wp-content/uploads/2021/02/333-scaled.jpg);
    }

    .link {
        outline: 0;
        border-bottom-color: rgb(29, 36, 40);
        font-weight: 700;
    }

    .bg-third {
        background-color: #1c2427 !important;
    }

    .card-header {
        background-color: #1c2427;
    }

    h1 {
        color: #1c2427;
    }

    .card {
        border-radius: 20px;
        z-index: 2;
    }

    .btn-third {
        background-color: #1b2225 !important;
    }

    .input-group-text {
        color: white;
        background-color: #1b2225;
        border: 0;
        border-radius: 10px;
    }

    .btn {
        color: white;
        font-size: 110%;
        border: none;
        padding: 15px 35px;
        border-radius: 90px 13px 90px 13px;
        font-family: 'Poppins';
    }

    .checkbox-lg .custom-control-label::before,
    .checkbox-lg .custom-control-label::after {
        top: 0.8rem;
        width: 1.55rem;
        height: 1.55rem;
    }

    .checkbox-lg .custom-control-label {
        padding-top: 16px;
        padding-left: 6px;
    }

    .required::before {
        color: red;
        font-weight: 600;
        content: '*';
        padding-left: 5px;
    }
</style>

<script>
    import Footer from '@/views/Public/Footer.vue'
    import LangSelector from '@/views/Public/LangSelector.vue'
    import Toaster from '@/components/UI/Toaster'
    import {mapState} from "vuex";

    export default {
        name: 'Login',
        components: {
            Footer,
            LangSelector,
            Toaster
        },
        data: function () {
            return {
                message: '',
                isPartner: true,
                cgv: false,
                society: '',
                home: process.env.VUE_APP_NEXTLEVEL_SITE,
                link: {
                    fr: {
                        login: 'login',
                        register: 'register',
                        home: process.env.VUE_APP_NEXTLEVEL_SITE,
                        forgottenPassword: 'oubli.php'
                    },
                    en: {
                        login: 'login_en.php',
                        register: 'register_en.php',
                        home: process.env.VUE_APP_NEXTLEVEL_SITE,
                        forgottenPassword: 'oubli_en.php'
                    }
                }
            }
        },
        computed: {
            ...mapState(['isMobile'])
        },
        mounted() {
            this.showMessage()
        },
        methods: {
            passwordForgottenLink: function (toLanguage = this.$i18n.locale) {
                return this.link[toLanguage].forgottenPassword
            },
            loginLink: function (toLanguage = this.$i18n.locale) {
                return this.link[toLanguage].login
            },
            registerLink: function (toLanguage = this.$i18n.locale) {
                return this.link[toLanguage].register
            },
            nlWebsite: function (toLanguage = this.$i18n.locale) {
                return this.link[toLanguage].home
            },
            showMessage: function () {
                var dataDiv = document.getElementById('vue-data')
                if (dataDiv) {
                    this.message = JSON.parse(dataDiv.dataset.message)
                    if (this.message.success) {
                        this.$refs.toaster.fire('success', this.message.details, this.message.success)
                    } else {
                        this.$refs.toaster.fire('error', this.message.details, this.message.error)
                    }
                }
            },
            toLocale: function (locale) {
                this.$i18n.locale = locale
            },
        }
    }
</script>
