<template>

    <div class="main-content section pt-0 ">

        <!-- Header -->
        <div class="header bg-gradient py-7 py-lg-8 pt-lg-9">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 px-5 mb-5 pt-2">
                            <router-link :to="home">
                                <img src="/assets/img/logo-NL-avec-SJ-blanc.png" alt=""/>
                            </router-link>
                        </div>
                        <div class="align-content-end">
                            <LangSelector @onLangChange="onLangChange"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page content -->
        <div class="container pb-5">
            <div class="row justify-content-center mb-5">
                <div class="col-lg-4 col-md-12 p-0">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-header bg-transparent">
                            <div class="text-muted text-center mt-2 w-100">
                                <h1>{{ $t('login.connexion') }}</h1>
                            </div>
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <form>
                                <div class="form-group mb-3 pt-4 pt-lg-0">
                                    <div class="input-group input-group-merge input-group-alternative">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-at"/></span>
                                        </div>
                                        <input
                                            v-model="email"
                                            class="form-control"
                                            name="username"
                                            placeholder="Email"
                                            type="email"
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group input-group-merge input-group-alternative">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-lock"/></span>
                                        </div>
                                        <input
                                            v-model="password"
                                            class="form-control"
                                            name="password"
                                            :placeholder="$t('login.password')"
                                            type="password"
                                        />
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-third mt-5 mb-2 mb-xl-4" @click.prevent="doLogin()">
                                        <i v-if="loginin" class="fas fa-circle-notch fa-spin mr-1"/>
                                        {{ $t('login.connexion') }}
                                    </button>

                                    <br/>
                                    {{ $t('login.or') }}
                                    <u>
                                        <strong>
                                            <router-link to="/register" class="link dark pl-1">
                                                {{ $t('login.register') }}
                                            </router-link>
                                        </strong>
                                    </u>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6"/>
                        <div class="col-6 text-right">
                            <router-link to="/reset" class="text-light">
                                <small>
                                    {{ $t('login.forgotten_password') }}
                                </small>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer :fixed="!isMobile"/>
        </div>
        <div class="d-none d-xl-block">
            <img class="bg-zebre" src="/assets/img/zebre-super-hero-qui-bouge.svg" alt=""/>
        </div>
    </div>
</template>

<script>
import Footer from '@/views/Public/Footer.vue'
import LangSelector from '@/views/Public/LangSelector.vue'
import PublicMixin from '@/views/Public/PublicMixin'
import {mapActions} from 'vuex'

export default {
    name: 'Login',
    title: 'register.login',
    components: {
        Footer,
        LangSelector
    },
    mixins: [PublicMixin],
    data: function () {
        return {
            message: '',
            email: null,
            password: null,
            loginin: false
        }
    },
    i18n: {
        messages: {
            en: {
                message: {
                    error: 'Invalid email or password'
                }
            },
            fr: {
                message: {
                    error: 'Email ou mot de passe invalid'
                }
            }
        }
    },
    methods: {
        ...mapActions('auth', ['login']),
        doLogin() {
            this.loginin = true
            this.login({email: this.email, password: this.password})
                .then(() => {
                    this.$router.push(this.$store.state.homepage)
                })
                .catch((error) => {
                    this.$store.dispatch('toastError', {
                        data: {
                            message: error.response.data.message
                        },
                        status: error.response.status
                    })
                })
                .finally(() => {
                    this.loginin = false
                })
        },
        onLangChange(lang) {
            this.$i18n.locale = lang
        }
    }
}
</script>