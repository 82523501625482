<template>
    <div>
        <div class="d-block d-sm-block d-md-block d-lg-none">
            <div class="container-fluid footer-mobile p-3">
                <div class="row">
                    <div class="col-md-12 mb-2 text-light">Copyright &copy; NextLevel</div>
                </div>
                <div class="row pl-3">
                    <a href="https://www.nextlevel.link/mentions-legales" target="_blank">{{
                        $t('global.legalnotice')
                    }}</a>
                    <a href="https://www.nextlevel.link/contact" target="_blank">{{ $t('layout.contact') }}</a>
                    /
                    <a href="#" data-toggle="modal" class="col-md-6" data-target="#cgv">{{
                        $t('layout.cgv_mobile')
                    }}</a>
                    <a href="https://www.nextlevel.link/faq-client-nextlevel" class="col-md-6" target="_blank">{{
                        $t('layout.faqcustommers')
                    }}</a>
                    <a href="https://www.nextlevel.link/faq-partenaire-nextlevel" class="col-md-6" target="_blank">{{
                        $t('layout.faqpartners')
                    }}</a>
                    <a href="https://www.nextlevel.link/plateforme-netlinking" class="col-md-6" target="_blank">{{
                        $t('layout.netlinkingplatform')
                    }}</a>
                </div>
            </div>
        </div>

        <div class="d-none d-sm-none d-md-none d-lg-none d-lg-block d-xl-block">
            <div id="offer-bar-celebrating" class="alert offBar active" :class="{ offBarFixed: fixed }">
                <div class="container-fluid p-3">
                    <div class="row offbar-d-flex">
                        <div class="col-md-12 col-sm-12 text-left offBar-content">
                            <div class="offBar-logo mr-5">Copyright &copy; NextLevel.link</div>
                            <div class="">
                              <a href="https://www.nextlevel.link/mentions-legales" target="_blank">{{ $t('global.legalnotice') }}</a>
                                /
                                <a href="https://www.nextlevel.link/contact" target="_blank">{{
                                    $t('layout.contact')
                                }}</a>
                                /
                                <a href="#" @click.prevent="openCgv">{{ $t('layout.cgv') }}</a>
                                /
                                <a href="https://www.nextlevel.link/faq-client-nextlevel" target="_blank">{{
                                    $t('layout.faqcustommers')
                                }}</a>
                                /
                                <a href="https://www.nextlevel.link/faq-partenaire-nextlevel" target="_blank">{{
                                    $t('layout.faqpartners')
                                }}</a>
                                /
                                <a href="https://www.nextlevel.link/plateforme-netlinking" target="_blank">{{
                                    $t('layout.netlinkingplatform')
                                }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <nl-modal ref="mentionsModal" :title="$t('global.legalnotice')" @hidden="closeMentions">
            <template>
                <img src="@/assets/img/img-mentions.png" alt="" />
            </template>
        </nl-modal>

        <nl-modal
            ref="cgvModal"
            :title="$t('layout.cgv') + ' - Version : ' + translatedTacs.version"
            @hidden="closeCgv"
        >
            <div v-html="translatedTacs.tac" />
        </nl-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'Footer',
        components: {},
        props: {
            fixed: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data: function () {
            return {
                link: {
                    fr: {
                        contact: process.env.NEXTLEVEL_URL + '/contact'
                    },
                    en: {
                        contact: process.env.NEXTLEVEL_URL + '/en/contact'
                    }
                },
                tacs: null
            }
        },
        computed: {
            translatedTacs() {
                return this.tacs ? this.tacs[this.$i18n.locale] : { version: '', tac: '' }
            }
        },
        created() {
            this.getLastTacs().then(response => {
                this.tacs = response.tacs
            })
        },
        methods: {
            ...mapActions('config', {
                getLastTacs: 'getLastTacs'
            }),
            contactLink: function (toLanguage = this.$i18n.locale) {
                return this.link[toLanguage].contact
            },
            openMentions: function () {
                this.$refs.mentionsModal.show()
            },
            closeMentions: function () {
                this.$refs.mentionsModal.hide()
            },
            openCgv: function () {
                this.$refs.cgvModal.show()
            },
            closeCgv: function () {
                this.$refs.cgvModal.hide()
            }
        }
    }
</script>

<style scoped>
    .footer-mobile {
        background: rgba(28, 36, 39, 0.85);
        margin-top: 2em;
        margin-bottom: 2em;
    }

    .text-xs {
        font-size: 12px;
    }

    .container-fluid {
        padding: 0;
    }

    a {
        color: #ccc;
        padding-top: 10px;
    }

    .offBarFixed {
        position: fixed;
    }

    .offBar {
        background: rgba(28, 36, 39, 0.85);
        z-index: 2;
        font-size: 16px;
        color: #ccc;
        padding: 0px;
        font-weight: 400;
        bottom: 20px;
        width: 80%;
        border-radius: 5px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%),
            0 -6px 16px -6px rgb(0 0 0 / 3%);
    }

    .offBar .offBar-content {
        display: flex;
        align-items: center;
    }

    .offBar .offBar-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .offBar .offBar-logo img {
        height: 50px;
    }
</style>
