<template>
    <div class="select-country px-3 py-2">
        <NlDropdown>
            <template #current>
                <flag :iso="language[$i18n.locale]" />
            </template>

            <NlDropdownItem v-for="(lang, name) in language" :key="lang" @click="$emit('onLangChange', name)">
                <flag :iso="lang" />
            </NlDropdownItem>
        </NlDropdown>
    </div>
</template>

<style scoped>
    .select-country {
        position: absolute;
        right: 0;
        top: 0;
    }
</style>
<script>
    import NlDropdown from '@/components/UI/NlDropdown'

    export default {
        name: 'LangSelector',
        components: { NlDropdown },
        props: ['link'],
        data: function () {
            return {
                locale: this.$i18n.locale,
                language: {
                    fr: 'fr',
                    en: 'us'
                }
            }
        },
        computed: {
            toLink() {
                return this.link || '#'
            }
        },
        created() {},
        mounted() {},
        methods: {}
    }
</script>
